import { VendorEnrollmentDetails } from './EnrollmentDetails';
import { Address } from './Market';
import { AccountRequest, EmailNotificationCadence } from './Account';
import { Opportunity, PublicOpportunityProfile } from './Opportunity';
import { CompanyManager } from './CompanyManager';
import { CompanyVendor } from './CompanyVendor';

export type CompanyNotificationPreferences = {
  lastSummaryNotifiedDate?: Date;
  emailCadence: EmailNotificationCadence;
};

export type Company = {
  id: number;
  title: string;
  /**
   * @deprecated use company owner instead
   */
  propertyManagerId?: string | null;
  createdAt: string;
  updatedAt: string;
  enrollmentDetails?: CompanyEnrollmentDetails;
  location?: Address;
  logo?: string;
  customDomain: string | null;
  subscriptionDetails?: CompanySubscriptionDetails;
  aboutUs?: string;
  workingWithUs?: string;
  profileDetails?: CompanyProfileDetails;
  opportunities?: Opportunity[];
  phoneNumber: string;
  notificationPreferences?: CompanyNotificationPreferences;
  companyOwner?: CompanyManager;
  // all company managers
  companyManagers?: CompanyManager[];
  // current company manager (if logged in as a company manager)
  companyManager?: CompanyManager;
};

export type CompanySubscriptionPrice = {
  id: string;
  lookupKey: string | null;
  amountCents: number | null;
  interval?: string;
  intervalCount?: number;
};

export type CompanySubscriptionDetails = {
  isSubscribed: boolean;
  isBasic: boolean;
  isPro: boolean;
  isEnterprise: boolean;
};

// These are settings an admin controls
export type AdminCompanyEnrollmentDetails = {
  requireVendorEnrollmentFee?: boolean;
  vendorEnrollmentFeePrice?: string;
  performTinCheck?: boolean;
  requestDocumentVerificationEnabled?: boolean;
  chatbot?: {
    promptLimit?: number;
  };
};

// These are settings a company owner controls
export type CompanyMemberEnrollmentDetails = {
  requireInsuranceAgencyVerification?: boolean;
  requireVendorServiceArea?: boolean;
  unresponsiveAgencyThreshold?: number | null;
};

export type CompanyEnrollmentDetails = AdminCompanyEnrollmentDetails &
  CompanyMemberEnrollmentDetails;

export type CompanyForm = {
  companyName: string;
  customDomain?: string | null;
  companyLocation: Address;
  firstName: string;
  lastName: string;
  businessPhoneNumber: string;
  phoneNumber: string;
  email: string;
  password?: string;
  enrollmentDetails: Omit<
    CompanyEnrollmentDetails,
    'unresponsiveAgencyThreshold'
  >;
  logo?: string;
};

export type AddCompanyRequest = Pick<
  Company,
  | 'title'
  | 'location'
  | 'enrollmentDetails'
  | 'logo'
  | 'phoneNumber'
  | 'notificationPreferences'
> & {
  companyOwner: Omit<AccountRequest, 'role'>;
};

export type UpdateCompanyRequest = Pick<
  Company,
  | 'title'
  | 'location'
  | 'enrollmentDetails'
  | 'logo'
  | 'customDomain'
  | 'aboutUs'
  | 'workingWithUs'
  | 'profileDetails'
  | 'phoneNumber'
  | 'notificationPreferences'
>;

export type MyCompaniesResponse = (CompanyVendor | Company)[];

export type AcceptCompanyInvitationRequest = {
  enrollmentDetails?: VendorEnrollmentDetails;
  companyId: number;
};

export type UpdateMyCompanyPaymentRequest = {
  companyId: number;
  stripeCheckoutSessionId: string;
};
export type UpdateCompanyVendorRequest = Pick<CompanyVendor, 'companyId'> & {
  companyVendorId: number;
  categoryIds?: number[];
};

export enum CompanyPaymentTerms {
  UNDER_NET_15 = 'UNDER_NET_15',
  NET_15 = 'NET_15',
  NET_30 = 'NET_30',
  NET_45 = 'NET_45',
  NET_60 = 'NET_60',
  OVER_NET_60 = 'OVER_NET_60',
  INSTANT = 'INSTANT',
  VARIABLE = 'VARIABLE',
}

export enum CompanyKnownForTags {
  RESPONSE_TIME_UNDER_24_HOURS = 'RESPONSE_TIME_UNDER_24_HOURS',
  RESPONSE_TIME_24_TO_48_HOURS = 'RESPONSE_TIME_24_TO_48_HOURS',
  RESPONSE_TIME_OVER_48_HOURS = 'RESPONSE_TIME_OVER_48_HOURS',
  DAILY_JOBS = 'DAILY_JOBS',
  MONTHLY_JOBS = 'MONTHLY_JOBS',
  RECURRING_JOBS = 'RECURRING_JOBS',
  MULTI_DAY_PROJECTS = 'MULTI_DAY_PROJECTS',
  PRE_APPROVED_BUDGET = 'PRE_APPROVED_BUDGET',
  STANDARD_PRICING = 'STANDARD_PRICING',
  PAID_TRIP_FEE = 'PAID_TRIP_FEE',
  TECHNOLOGY_TRAINING = 'TECHNOLOGY_TRAINING',
  ON_SITE_SUPPORT = 'ON_SITE_SUPPORT',
  ADMINISTRATIVE_SUPPORT = 'ADMINISTRATIVE_SUPPORT',
}

export type CompanyProfileDetails = {
  website?: string;
  unitCount?: number | null;
  paymentTerms?: CompanyPaymentTerms;
  keyRequirements?: string[];
  knownFor?: CompanyKnownForTags[];
};

export type PublicCompanyProfile = Pick<
  Company,
  'title' | 'aboutUs' | 'workingWithUs' | 'logo'
> & {
  location?: Omit<Address, 'id' | 'createdAt' | 'updatedAt'>;
  phoneNumber: string;
  profileDetails?: CompanyProfileDetails;
  opportunities?: PublicOpportunityProfile[];
};
