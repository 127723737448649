export const opportunityBasePath = '/opportunities';
export const allOpportunityBasePath = '/all-opportunities';
export const opportunityPath = (id: number) => `/opportunities/${id}`;
export const opportunityEditPath = (id: number) =>
  `/opportunities/${id}/edit-opportunity`;
export const opportunityAddPath = `/opportunities/add`;
export const opportunityApplicationPath = (
  opportunityId: number,
  opportunityVendorId: number,
) => `/opportunities/${opportunityId}/applications/${opportunityVendorId}`;
