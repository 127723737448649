import { CompanyVendorFilters } from 'common/types/Vendor';

export const vendorsPath = '/vendors';
export const vendorPath = (vendorId: string) => `/vendors/${vendorId}`;
export const vendorAddPath = '/vendors/add-vendor';
export const invitedVendorsPathWithFilters = (filters?: CompanyVendorFilters) =>
  `/vendors?vendorsTab=1&initialTableState=${encodeURIComponent(JSON.stringify(filters ?? {}))}`;
export const complianceStatusVendorsPathWithFilters = (
  filters?: CompanyVendorFilters,
) =>
  `/vendors?initialTableState=${encodeURIComponent(JSON.stringify(filters ?? {}))}`;
