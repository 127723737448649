import { ReactNode } from 'react';
import CompanyIcon from 'styleguide/src/components/icons/CompanyIcon';
import FancyDashboardIcon from 'styleguide/src/components/icons/FancyDashboardIcon';
import CheckListIcon from 'styleguide/src/components/icons/CheckListIcon';
import { AllowedRoles } from 'types/roles';
import UserIcon from 'styleguide/src/components/icons/UserIcon';
import SettingsIcon from 'styleguide/src/components/icons/SettingsIcon';
import FindVendorIcon from 'styleguide/src/components/icons/FindVendorIcon';
import ToolboxIcon from 'styleguide/src/components/icons/ToolboxIcon';
import LocationIcon from 'styleguide/src/components/icons/LocationIcon';
import HandshakeIcon from 'styleguide/src/components/icons/HandshakeIcon';
import VendorIQIcon from 'styleguide/src/components/icons/VendorIQIcon';
import { coordAllVendorsPath } from './coordinators/vendors';
import { coordWorkingList } from './coordinators/working-list';
import WorkIcon from 'styleguide/src/components/icons/WorkIcon';
import AltDashboardIcon from 'styleguide/src/components/icons/AltDashboardIcon';
import { dashboardPath } from './propertyManager/dashboard';
import { AccountRole } from 'common/types/AccountRole';
import { CompanyManagerPermissionFlags } from 'common/types/CompanyManager';
import { baseCompliancePath } from './vendor/compliance';
import { searchPath } from './propertyManager/search';
import { vendorIqPath } from './propertyManager/vendoriq';
import { vendorsPath } from './propertyManager/vendors';
import {
  allOpportunityBasePath,
  opportunityBasePath,
} from './propertyManager/opportunity';
import { coordAllCompaniesPath } from './coordinators/companies';
import { baseOpportunityPath } from './vendor/opportunity';
import { coordDataManagementBasePath } from './coordinators/dataManagement';
import MoreIcon from 'styleguide/src/components/icons/MoreIcon';

type NavItems = {
  href: string;
  title: string;
  icon: ReactNode;
}[];

type DefaultRoleRoute = {
  [key in AllowedRoles]: string;
};

const sharedRoutes: NavItems = [
  {
    href: '/settings',
    title: 'Settings',
    icon: <SettingsIcon />,
  },
];

const adminRoutes: NavItems = [
  {
    href: coordWorkingList,
    title: 'Working List',
    icon: <FancyDashboardIcon />,
  },
  {
    href: coordAllVendorsPath,
    title: 'Vendors',
    icon: <UserIcon />,
  },
  {
    href: coordAllCompaniesPath,
    title: 'Companies',
    icon: <CompanyIcon />,
  },
  {
    href: allOpportunityBasePath,
    title: 'Opportunities',
    icon: <WorkIcon />,
  },
  {
    href: coordDataManagementBasePath,
    title: 'Data Manager',
    icon: <MoreIcon />,
  },
  ...sharedRoutes,
];

const vendorRoutes: NavItems = [
  {
    href: baseCompliancePath,
    title: 'Compliance',
    icon: <FancyDashboardIcon />,
  },
  {
    href: baseOpportunityPath,
    title: 'Opportunities',
    icon: <HandshakeIcon />,
  },
  ...sharedRoutes,
];

const propertyManagerRoutes = (
  permissions?: CompanyManagerPermissionFlags,
): NavItems => [
  {
    href: dashboardPath,
    title: 'Dashboard',
    icon: <AltDashboardIcon />,
  },
  {
    href: vendorsPath,
    title: 'My Vendors',
    icon: <UserIcon />,
  },
  {
    href: searchPath,
    title: 'Find Vendors',
    icon: <FindVendorIcon />,
  },
  ...(permissions?.accessOpportunities
    ? [
        {
          href: opportunityBasePath,
          title: 'Opportunities',
          icon: <ToolboxIcon />,
        },
      ]
    : []),
  {
    href: '/locations',
    title: 'Locations',
    icon: <LocationIcon />,
  },
  {
    href: vendorIqPath,
    title: 'VendorIQ',
    icon: <VendorIQIcon />,
  },
  {
    href: '/requirements',
    title: 'Requirements',
    icon: <CheckListIcon />,
  },
  ...sharedRoutes,
];

type settings = {
  role: AccountRole;
  permissions?: CompanyManagerPermissionFlags;
};

export const getNavItems = ({ role, permissions }: settings): NavItems => {
  const map = {
    ADMIN: adminRoutes,
    COORDINATOR: adminRoutes,
    VENDOR: vendorRoutes,
    PROPERTY_MANAGER: propertyManagerRoutes(permissions),
  };
  if (role in map) {
    return map[role as AllowedRoles];
  }
  return [];
};

export const DEFAULT_ROUTE: DefaultRoleRoute = {
  ADMIN: coordWorkingList,
  COORDINATOR: coordWorkingList,
  VENDOR: baseCompliancePath,
  PROPERTY_MANAGER: dashboardPath,
};
